














import {Component, Vue} from 'vue-property-decorator';
import {CombinationService, TrackService} from '@/api';
import {Route} from 'vue-router';
import {CombinationCompilation, TrackListItem} from '@/types';
import UPagination from '@/ui-lib/pagination/Pagination.vue';
import UBlock from '@/ui-lib/block/Block.vue';
import TrackTableList from '@/components/track/TrackTableList.vue';

const limit = 30;

const getStart = (route: Route) => typeof route.query.start === 'string' ? parseInt(route.query.start, 10) : 0;

@Component<CombinationTracksView>({
  components: {
    UBlock,
    UPagination,
    TrackTableList,
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const combinationId = parseInt(to.params.id, 10);
      const compilations = await CombinationService.compilations(combinationId);

      const {list, total} = await TrackService.list({
        start: getStart(to),
        limit: limit,
        compilationsIds: compilations.map((c) => c.compilationId),
      });

      next((vm) => {
        vm.compilations = compilations;
        vm.tracks = list;
        vm.total = total;
      });
    } catch (e) {
      next(e);
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      await this.updateList(to);

      next();
    } catch (e) {
      next(e);
    }
  },
})
export default class CombinationTracksView extends Vue {
  private total = 0;
  private tracks: TrackListItem[] = [];
  private compilations: CombinationCompilation[] = [];

  private get limit() {
    return limit;
  }

  private get start() {
    return getStart(this.$route);
  }

  private changeStart(newStart: number) {
    this.$router.push({
      query: {
        ...this.$route.query,
        start: newStart.toString(),
      },
    });
  }

  private async updateList(route: Route) {
    const {list, total} = await TrackService.list({
      start: getStart(route),
      limit: limit,
      compilationsIds: this.compilations.map((c) => c.compilationId),
    });

    this.tracks = list;
    this.total = total;
  }
}
